export default {
	items: [
		{
			name: 'dashboard',
			url: '/home',
			icon: 'fa fa-columns',
		},
		{
			name: 'store',
			url: '/store',
			icon: 'fas fa-shopping-cart',
		},
		{
			name: 'orders',
			url: '/orders/',
			icon: 'fa fa-list-alt',
			children: [
				{
					name: 'orderHistory',
					url: '/orders/all',
					icon: 'fa fa-bars',
				},
				{
					name: 'referredOrders',
					url: '/orders/referred',
					icon: 'fas fa-bars',
				},
				{
					name: 'downloads',
					url: '/orders/downloads',
					icon: 'fas fa-download',
				},
			],
		},
		{
			name: 'productReviews',
			url: '/product-reviews',
			icon: 'fas fa-comment-alt',
		},
		{
			name: 'coupons',
			url: '/coupons',
			icon: 'fas fa-ticket-alt',
		},
		{
			name: 'wallets',
			url: '/wallets/',
			icon: 'fa fa-wallet',
		},
		{
			name: 'sponsoredCustomers',
			url: '/sponsored-customers',
			icon: 'fa fa-user-tag',
		},
		{
			name: 'autoshipInformation',
			url: '/autoship-information',
			icon: 'fas fa-truck',
		},
		{
			name: 'userAnnouncements',
			url: '/user-announcements',
			icon: 'fas fa-bullhorn',
		},
		{
			name: 'faq',
			url: '/faq',
			icon: 'fas fa-question',
		},
		{
			name: 'support',
			url: '/support',
			icon: 'fas fa-life-ring',
		},
	],
};
