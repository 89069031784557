export default {
	items: [
		{
			name: 'dashboard',
			url: '/home',
			icon: 'fa fa-columns',
		},
		{
			name: 'adminDashboard',
			url: '/corporate-dashboard',
			icon: 'fa fa-chart-line',
		},
		{
			name: 'reports',
			url: '/reports/',
			icon: 'fa fa-clipboard',
			children: [
				{
					name: 'commissions',
					url: '/reports/commissions',
					icon: 'fa fa-file',
				},
				{
					name: 'payouts',
					url: '/reports/payout/week',
					icon: 'fa fa-file',
				},
				{
					name: 'sales',
					url: '/reports/sales',
					icon: 'fa fa-file',
				},
				{
					name: 'salesDetails',
					url: '/reports/sales-details',
					icon: 'fa fa-file',
				},
				{
					name: 'agencySalesDetailsCorporate',
					url: '/reports/sales-agency/sales',
					icon: 'fa fa-file',
				},
				{
					name: 'taxes',
					url: '/reports/taxes',
					icon: 'fa fa-file',
				},
				{
					name: 'refunds',
					url: '/reports/refunds',
					icon: 'fa fa-file',
				},
				{
					name: 'totalEarnings',
					url: '/reports/total-earnings',
					icon: 'fa fa-file',
				},
				{
					name: 'personalBusinessVolume',
					url: '/reports/personal-business-volume',
					icon: 'fa fa-file',
				},
				{
					name: 'unilevelRanks',
					url: '/reports/unilevel-ranks',
					icon: 'fa fa-file',
				},
				{
					name: 'rankHistories',
					url: '/reports/rank-histories',
					icon: 'fa fa-file',
				},
				{
					name: 'risingStars',
					url: '/reports/rising-stars',
					icon: 'fa fa-file',
				},
				{
					name: 'bv',
					url: '/reports/bv',
					icon: 'fa fa-file',
				},
				{
					name: 'pendingBv',
					url: '/reports/pending-bv',
					icon: 'fa fa-file',
				},
				{
					name: 'autoshipSummary',
					url: '/reports/autoship-summary',
					icon: 'fa fa-file',
				},
				{
					name: 'autoshipHistory',
					url: '/reports/autoship-history',
					icon: 'fa fa-file',
				},
				{
					name: 'FraudOrders',
					url: '/reports/fraud-orders',
					icon: 'fa fa-file',
				},
				{
					name: 'userNotes',
					url: '/reports/user-notes',
					icon: 'fa fa-file',
				},
				{
					name: 'activationHistory',
					url: '/reports/activation-history',
					icon: 'fa fa-file',
				},
				{
					name: 'lifetimeValue',
					url: '/reports/lifetime-value',
					icon: 'fa fa-file',
				},
			],
		},
		{
			name: 'myNetwork',
			url: '/my-network/',
			icon: 'fa fa-share-alt',
			children: [
				{
					name: 'binaryTree',
					url: '/my-network/binary-tree',
					icon: 'fas fa-network-wired',
				},
				{
					name: 'unilevelTree',
					url: '/my-network/unilevel-tree',
					icon: 'fas fa-level-down-alt',
				},
				{
					name: 'unilevelSummary',
					url: '/my-network/unilevel-summary',
					icon: 'fas fa-sort-amount-down',
				},
				{
					name: 'downlinesInfo',
					url: '/my-network/downlines-info',
					icon: 'fa fa-user',
				},
				{
					name: 'distributorOrganization',
					url: '/my-network/distributor-organization',
					icon: 'fa fa-users',
				},
				{
					name: 'sponsoredDistributors',
					url: '/my-network/sponsored-distributors',
					icon: 'fa fa-user',
				},
				{
					name: 'sponsoredCustomers',
					url: '/my-network/sponsored-customers',
					icon: 'fa fa-user-tag',
				},
			],
		},
		{
			name: 'userManagement',
			url: '/manage/',
			icon: 'fa fa-users',
			children: [
				{
					name: 'users',
					url: '/manage/users',
					icon: 'fa fa-user',
				},
				{
					name: 'customers',
					url: '/manage/customers',
					icon: 'fa fa-user-tag',
				},
				{
					name: 'corporates',
					url: '/manage/corporates',
					icon: 'fas fa-user-tie',
				},
			],
		},
		{
			name: 'orders',
			url: '/orders/',
			icon: 'fa fa-list-alt',
			children: [
				{
					name: 'orderHistory',
					url: '/orders/all',
					icon: 'fa fa-bars',
				},
				{
					name: 'referredOrders',
					url: '/orders/referred',
					icon: 'fas fa-bars',
				},
				{
					name: 'multiPaymentOrders',
					url: '/orders/multi-payment-plan',
					icon: 'fa fa-tasks',
				},
				{
					name: 'settlementOrders',
					url: '/orders/settlements',
					icon: 'fab fa-amazon',
				},
				{
					name: 'eventTickets',
					url: '/orders/event-tickets',
					icon: 'fas fa-calendar-check',
				},
				{
					name: 'pickupOrders',
					url: '/orders/pickup',
					icon: 'fas fa-shopping-bag',
				},
				{
					name: 'downloads',
					url: '/orders/downloads',
					icon: 'fas fa-download',
				},
				{
					name: 'videos',
					url: '/orders/videos',
					icon: 'fas fa-video',
				},
			],
		},
		{
			name: 'orderVerifications',
			url: '/order-verifications',
			icon: 'fas fa-credit-card',
		},
		{
			name: 'cashPayments',
			url: '/cash-payments',
			icon: 'fas fa-dollar-sign',
		},
		{
			name: 'wallets',
			url: '/wallets/',
			icon: 'fa fa-wallet',
			// children: [
			// 	{
			// 		name: 'commissionWallet',
			// 		url: '/wallets/transactions/commission',
			// 		icon: 'fa fa-file',
			// 	},
			// 	{
			// 		name: 'depositWallet',
			// 		url: '/wallets/transactions/deposit',
			// 		icon: 'fa fa-file',
			// 	},
			// ],
		},
		{
			name: 'coupons',
			url: '/coupons',
			icon: 'fas fa-ticket-alt',
		},
		{
			name: 'customCarts',
			url: '/personalized-carts',
			icon: 'fas fa-cart-plus',
		},
		{
			name: 'agencyPromotions',
			url: '/agency-promotions',
			icon: 'fas fa-tag',
		},
		{
			name: 'giftOrders',
			url: '/gift-orders',
			icon: 'fas fa-gift',
		},
		{
			name: 'productReviews',
			url: '/product-reviews',
			icon: 'fas fa-comment-alt',
		},
		{
			name: 'inventory',
			url: '/inventory',
			icon: 'fa fa-warehouse',
			children: [
				{
					name: 'inventorySummary',
					url: '/inventory/summary',
					icon: 'fa fa-layer-group',
				},
				{
					name: 'inventoryInTransit',
					url: '/inventory/in-transit',
					icon: 'fa fa-truck-loading',
				},
				{
					name: 'inventoryLogs',
					url: '/inventory/logs',
					icon: 'fa fa-clipboard-list',
				},
			],
		},
		{
			name: 'pushNotifications',
			url: '/push-notifications',
			icon: 'fas fa-bell',
		},
		{
			name: 'banners',
			url: '/banners',
			icon: 'far fa-images',
		},
		{
			name: 'highlightVideos',
			url: '/highlight-videos',
			icon: 'fa fa-play',
		},
		{
			name: 'mediaLibrary',
			url: '/media-library',
			icon: 'fas fa-newspaper',
		},
		{
			name: 'sendSms',
			url: '/sms',
			icon: 'fas fa-envelope',
		},
		{
			name: 'announcements',
			url: '/announcements',
			icon: 'fas fa-bullhorn',
		},
		{
			name: 'faq',
			url: '/faq',
			icon: 'fas fa-question',
		},
		{
			name: 'settings',
			url: '/settings',
			icon: 'fas fa-tools',
		},
	],
};
